import React from "react";
import { Link } from "gatsby";

import "@reach/menu-button/styles.css";
import { menu, menuButton } from "./styles/user-menu.module.scss";

const UserMenu = () => {
  return (
    <div className={menu}>
      <Link className={menuButton} to="/tools">
        Tools
      </Link>
    </div>
  );
};

export default UserMenu;
