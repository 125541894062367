import React, { createContext, useReducer } from "react";
import {
  DEFAULT_OPTIONS,
  reduceUserOptions,
  Options,
} from "../services/options";

type ContextProps = {
  userOptions: Options;
  setUserOptions: (arg0?: Partial<Options>) => void;
};

const AppContext = createContext<Partial<ContextProps>>({});

type AppProviderProps = {
  children: React.ReactNode[] | React.ReactNode;
};

const AppProvider = ({ children }: AppProviderProps) => {
  const [userOptions, setUserOptions] = useReducer(reduceUserOptions, {
    ...DEFAULT_OPTIONS,
  } as Options);

  return (
    <AppContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        userOptions,
        setUserOptions,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

type AppContextProviderProps = {
  children: React.ReactNode[] | React.ReactNode;
};

const AppContextProvider = ({ children }: AppContextProviderProps) => (
  <AppProvider>{children}</AppProvider>
);

export { AppContext, AppContextProvider };
