import React, {useRef, useState} from 'react';
import clsx from 'clsx';
import {Link} from 'gatsby';
import {useClickAway, useIntersection} from 'react-use';

import Logo from '../content/logo.svg';
import UserMenu from './user-menu';

import {
  header,
  header__stuck,
  stickyCanary,
  logo,
  logo__persistent,
  navigation,
  navigation__open,
  navigation_links,
  home,
  hamburger
} from './styles/header.module.scss';

const logoStyle = {
  width: '32px',
  height: '32px'
};

type Props = {
  className?: string;
  isMarketingNavDisplayed?: boolean;
};

const Header = ({className, isMarketingNavDisplayed = true}: Props) => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const navRef = useRef<HTMLDivElement>(null);
  const stickyRef = useRef<HTMLDivElement>(null);

  useClickAway(navRef, () => {
    setIsNavOpen(false);
  });

  const intersection = useIntersection(stickyRef, {});

  const toggleNav = () => {
    setIsNavOpen(() => !isNavOpen);
  };

  return (
    <>
      <div ref={stickyRef} className={stickyCanary}/>
      <div
        className={clsx(header, className, {
          [header__stuck]: intersection ?
            intersection.intersectionRatio < 0.1 :
            false
        })}
        role="banner"
      >
        <Link
          to="/"
          className={clsx(logo, {[logo__persistent]: !isMarketingNavDisplayed})}
          aria-label="Home"
        >
          <Logo style={logoStyle}/>
        </Link>
        <nav
          ref={navRef}
          className={clsx(navigation, {[navigation__open]: isNavOpen})}
          role="navigation"
        >
          {isMarketingNavDisplayed ? (
            <>
              <button
                type="button"
                className={hamburger}
                aria-label="Expand navigation"
                onClick={toggleNav}
              >
                ☰
              </button>
              <div className={navigation_links}>
                <Link className={home} to="/">
                  Home
                </Link>
                <Link to="/discovery/">Our Discovery</Link>
                <Link to="/contact/">Contact</Link>
              </div>
            </>
          ) : null}
        </nav>
        <UserMenu/>
      </div>
    </>
  );
};

export default Header;
