import React from "react";
import { Link } from "@reach/router";

import Facebook from "../content/icons/facebook.svg";
import Instagram from "../content/icons/instagram.svg";
import YouTube from "../content/icons/youtube.svg";

import {
  footer,
  links,
  privacy,
  socialIcon,
} from "./styles/footer.module.scss";

const Footer = () => (
  <div className={footer} role="contentinfo">
    <div className={links}>
      <Link to="/terms/">Terms</Link>
      <Link to="/privacy/">Privacy</Link>
      <a href="https://www.facebook.com/readsummerbell/">
        <Facebook aria-label="Summerbell Facebook" className={socialIcon} />
      </a>
      <a href="https://www.instagram.com/readsummerbell/">
        <Instagram aria-label="Summerbell Instagram" className={socialIcon} />
      </a>
      <a href="https://www.youtube.com/channel/UCn4pcVkNzU1u-dzHiOvlzeA">
        <YouTube aria-label="Summerbell YouTube" className={socialIcon} />
      </a>
    </div>
    <div className={privacy}>
      © 2024 Summerbell Reading Method LLC - All rights reserved, including the
      arc icons. Patent pending.
    </div>
  </div>
);

export default Footer;
