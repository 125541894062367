import React from "react";
import MetaTags from "react-meta-tags";

import { AppContextProvider } from "./app-context";
import Footer from "./footer";
import Header from "./header";
import HelpButton from "./help-button";

import { wrapper, contentWrapper } from "./styles/layout.module.scss";

type Props = {
  children: React.ReactNode;
  title?: string;
  metaDescription?: string;
  isMarketingNavDisplayed?: boolean;
  isTitleDisplayed?: boolean;
};

const Layout = ({
  children,
  title,
  metaDescription,
  isMarketingNavDisplayed = true,
  isTitleDisplayed = true,
}: Props) => {
  const metaTitle = `Summerbell${title ? ` - ${title}` : ""}`;

  const metas = [
    {
      property: "og:title",
      content: metaTitle,
    },
    {
      property: "og:type",
      content: "website",
    },
    {
      name: "twitter:card",
      content: "summary",
    },
    {
      name: "twitter:title",
      content: metaTitle,
    },
  ];
  if (metaDescription) {
    metas.push(
      {
        name: "description",
        content: metaDescription,
      },
      {
        property: "og:description",
        content: metaDescription,
      },
      {
        name: "twitter:description",
        content: metaDescription,
      }
    );
  }

  return (
    <div>
      <MetaTags>
        <title>{metaTitle}</title>
        {metas.map((meta) => (
          <meta
            key={meta.name ?? meta.property}
            name={meta.name}
            property={meta.property}
            content={meta.content}
          />
        ))}
        <link rel="preconnect" href="https://static.zdassets.com" />
        <link rel="preconnect" href="https://www.googletagmanager.com" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "url": "https://www.readsummerbell.com",
              "logo": "https://www.readsummerbell.com/logo.png"
            }
          `}
        </script>
      </MetaTags>
      <AppContextProvider>
        <Header isMarketingNavDisplayed={isMarketingNavDisplayed} />
        <div className={wrapper}>
          <div className={contentWrapper} role="main">
            {title && isTitleDisplayed && <h1>{title}</h1>}
            {children}
          </div>
          <HelpButton />
          <Footer />
        </div>
      </AppContextProvider>
    </div>
  );
};

export default Layout;
