export const DEFAULT_OPTIONS = {
  fontSize: 18,
  letterSpacing: 35,
  arcHeight: 5,
  width: 1000,
};

export const reduceUserOptions = (options?: Options, newOptions?: Options) => {
  if (options && newOptions) {
    return { ...options, ...newOptions };
  }

  return DEFAULT_OPTIONS;
};

type OptionsProps = {
  fontSize: number;
  letterSpacing: number;
  arcHeight: number;
  width: number;
};

export class Options {
  [key: string]: number;

  fontSize: number;
  letterSpacing: number;
  arcHeight: number;
  width: number;

  constructor({ fontSize, letterSpacing, arcHeight, width }: OptionsProps) {
    this.fontSize = fontSize;
    this.letterSpacing = letterSpacing;
    this.arcHeight = arcHeight;
    this.width = width;
  }
}
