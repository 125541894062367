import React from 'react';
import loadable from '@loadable/component';
import pMinDelay from 'p-min-delay';

const ZENDESK_KEY = '8423b5af-7136-4e48-a61c-5a8b03830526';

const ZENDESK_SETTINGS = {
  webWidget: {chat: {connectOnPageLoad: false}},
  color: {
    theme: '#bf38cc'
  },
  launcher: {
    label: {
      '*': 'Contact us'
    }
  }
};

const HelpButton = () => {
  // Delay ZenDesk load for 4s to avoid requesting ~400KB of resources during
  // page load.
  const Zendesk = loadable(async () =>
    pMinDelay(import('react-zendesk'), 4000)
  );

  return <Zendesk zendeskKey={ZENDESK_KEY} {...ZENDESK_SETTINGS}/>;
};

export default HelpButton;
